import { TourDepartureState } from '@/redux/TourDepartureState';
import { RequestTourDeparturesFailureAction } from '@/redux/actions/tour/RequestTourDeparturesFailureAction';
import { RequestTourDeparturesSuccessAction } from '@/redux/actions/tour/RequestTourDeparturesSuccessActions';
import { Action } from '@reduxjs/toolkit';
import * as TYPES from '../actions/types';
import { TourDeparture } from '@/endurance/tours';
import { TourDeparture as Departure } from '@/core';
import { parseISO } from 'date-fns';

const initialState = {
    loading: false,
    departures: undefined,
    errors: [],
};

function requestTourDepartures(state: TourDepartureState): TourDepartureState {
    return {
        ...state,
        errors: [],
        loading: true,
    };
}

function requestTourDeparturesSuccess(
    state: TourDepartureState,
    action: RequestTourDeparturesSuccessAction
): TourDepartureState {
    return {
        ...state,
        loading: false,
        departures: transformDepartures(action.departures, action.tourId),
        errors: state.errors,
    };
}

function transformDepartures(
    departures: TourDeparture[],
    tourId: string
): Departure[] {
    return departures.map(d => ({
        tourId: tourId,
        departureId: d.departureId,
        startDate: parseISO(d.startDate),
        endDate: parseISO(d.endDate),
        spaces: d.spaces,
        discountPercent: d.discountPercent,
        isGuaranteedDeparture: d.guaranteedDeparture,
        balanceDueDate: parseISO(d.balanceDue),
        currencyCode: d.originalPrice.currencyCode,
        sellingPrice: d.adultPrice,
        sellingPricePerDay: d.adultPricePerDay,
        originalPrice: d.originalPrice,
        discount: d.discount,
        deposit: d.deposit,
    }));
}

const requestTourDepartureFailure = (
    state: TourDepartureState,
    action: RequestTourDeparturesFailureAction
): TourDepartureState => ({
    ...state,
    loading: false,
    departures: undefined,
    errors: action.errors,
});

const departures = (
    state: TourDepartureState = initialState,
    action: Action
): TourDepartureState => {
    const handlers: {
        [key: string]: (
            s: TourDepartureState,
            action?: Action
        ) => TourDepartureState;
    } = {
        [TYPES.REQUEST_TOUR_DEPARTURES]: requestTourDepartures,
        [TYPES.REQUEST_TOUR_DEPARTURES_SUCCESS]: requestTourDeparturesSuccess,
        [TYPES.REQUEST_TOUR_DEPARTURES_FAILURE]: requestTourDepartureFailure,
    };
    const handler: (
        site: TourDepartureState,
        action?: Action
    ) => TourDepartureState = handlers[action.type];

    return handler ? handler(state, action) : state;
};

export default departures;
