enum Action {
    click = 'Click',
    select = 'Select',
    submit = 'Submit',
    data = 'Data',
    slideChanged = 'SlideChanged',
    highlight = 'Highlight',
    featureExposure = 'FeatureExposure',
    error = 'Error',
    open = 'Open',
}

const ActionMinifiedMap: { [key in Action]: string } = {
    [Action.click]: 'CL',
    [Action.select]: 'SEL',
    [Action.submit]: 'SUB',
    [Action.data]: 'DATA',
    [Action.slideChanged]: 'SLC',
    [Action.highlight]: 'HL',
    [Action.featureExposure]: 'FE',
    [Action.error]: 'ERR',
    [Action.open]: 'O',
};

export { Action, ActionMinifiedMap };
